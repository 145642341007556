import React from "react"
import Navbar from "../components/navbar"
import styled from "styled-components"
import BadgeLink from "../components/badge_link"
import { faGithub, faLinkedinIn, faHackerrank } from '@fortawesome/free-brands-svg-icons' 
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import picture from "../../static/picture.jpg"

const AboutText = styled.div`
  width: 66.6667%;
  display: flex;
  justify-content: center;
  margin: 0px auto;
`

const AboutTitle = styled.h2`
  width: 66.6667%;
  display: flex;
  margin: 0px auto;
  padding-top: 24px;
`

const BadgeLinkLayout = styled.div`
  text-align:center;
  padding-top: 2.5rem;
  padding-bottom 2rem;
`

const AboutTextv2 = styled.div`
  max-width: 38em;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  display: block;
  color: #3e4444;
`

const AboutTitlev2 = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-family: open sans,sans-serif;
`

const Profile = styled.div`
  text-align: center;
  margin-top: 3rem;
  @media (max-width: 768px){
    margin-top: 5rem;
  }
`

const ProfilePic = styled.img`
  border-radius: 50%;
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.125);
`

const Name = styled.h1`
  font-family: open sans,sans-serif;
  font-size: 3rem;
  margin-bottom: .5rem;
  margin-top: 0;
`

const OuterContainerWrapper = styled.div`
  margin-top: 110px;
`

export default function About() {
  return (
    <>
        <Navbar></Navbar>
        <OuterContainerWrapper>
          <Profile>
            <ProfilePic src={picture}></ProfilePic>
          </Profile>

          <AboutTextv2>
          <AboutTitlev2> About me</AboutTitlev2>
            <p>
            Who am I?
            <br></br>
            <br></br>
            I'm a Software Engineer who likes to automate, abstract, design computer programs.I enjoy to divide and conquer complex problems.
            <br></br>
            <br></br>
            Why Programing?
            <br></br>
            <br></br>
            I wanted to learn programming in order to make games, then I made programs that played games (bots), it took me some time to realize the potential impact programs can have on all peoples lives. Now my goal is to create reliable software and provide solutions to users' problems.
            <br></br>
            <br></br>
            Why Electronics?
            <br></br>
            <br></br>
            Since my childhood, I was always curious about how do things work. Shows like "Mythbusters" and "How It's Made" were my favorites. I asked myself how computers work which led me to study Electrical and Electronics Engineering. Ability to watch your circuits run also brings a different kind of enjoyment into your life.
            

            </p>
          </AboutTextv2>
          <BadgeLinkLayout>
            <BadgeLink href="https://www.linkedin.com/in/bayram-kaya/" icon={faLinkedinIn}/>
            <BadgeLink href="https://github.com/ByK95" target="_blank" icon={faGithub}/>
            <BadgeLink href="https://www.hackerrank.com/byrmkaya97" icon={faHackerrank}/>
            <BadgeLink href="mailto:byrmkaya97@gmail.com" icon={faEnvelope}/>
          </BadgeLinkLayout>
        </OuterContainerWrapper>
    </>
  )
}
