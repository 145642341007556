import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


const BadgeWrapper = styled.div`
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
    margin 8px;
`

const IconBack = styled(FontAwesomeIcon)`
    color:#e6e6e6;
    font-size:3em;
`

const IconFront = styled(FontAwesomeIcon)`
    font-size: 1.5em;
    position: absolute;
    left: -50%;
    top: -50%;
    margin-left: 32px;
    margin-top: 28px;
`

export default function Badge(props) {
    return (
        <BadgeWrapper>
            <IconBack icon={faCircle} />
            <IconFront icon={props.icon} />
        </BadgeWrapper>
    );
}